<!--
 * @Description: 
-->
<template>
    <div id="app">
        <VueDPlayer ref="player" :options="options" style="height:100vh"></VueDPlayer>
    </div>
</template>

<script>
import VueDPlayer from "vue-dplayer";
import "vue-dplayer/dist/vue-dplayer.css";
const getUrlParams = function(name){
	const keyValue = location.href.split('?')[1]&&location.href.split('?')[1].split('&')
	if(!keyValue) return false
	const key = {}
	keyValue.forEach((item)=>{
		const a = item.split('=')
		key[a[0]] = a[1]
	})
	return key[name]
}



export default {
    name: "App",
    data() {
        return {
            options: {
                video: {
                    url: `http://cdn.lysd.xyz/thermage/${getUrlParams('url')}`,
                    pic:`http://cdn.lysd.xyz/thermage/image/${getUrlParams('url').split('.')[0]}.jpg`
                },
                autoplay: false
            },
        };
    },
    components: {
        VueDPlayer,
    },
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    font-size: 0px;
}
body{
  margin: 0px;
}
</style>
